@font-face {
    font-family: GerberaRegular;
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/Gerbera-Regular.eot") format('eot'), url("./assets/fonts/Gerbera-Regular.woff2") format('woff2'), url("./assets/fonts/Gerbera-Regular.woff") format('woff'), url("./assets/fonts/Gerbera-Regular.ttf") format('truetype');
    font-display: swap;
}
@font-face {
    font-family: GerberaMedium;
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/Gerbera-Medium.eot") format('eot'), url("./assets/fonts/Gerbera-Medium.woff2") format('woff2'), url("./assets/fonts/Gerbera-Medium.woff") format('woff'), url("./assets/fonts/Gerbera-Medium.ttf") format('truetype');
    font-display: swap;
}
@font-face {
    font-family: GerberaBold;
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/Gerbera-Bold.eot") format('eot'), url("./assets/fonts/Gerbera-Bold.woff2") format('woff2'), url("./assets/fonts/Gerbera-Bold.woff") format('woff'), url("./assets/fonts/Gerbera-Bold.ttf") format('truetype');
    font-display: swap;
}
html,
body {
    height: 100%;
}

body,
html,
p,
ul,
ol,
li,
dl,
dd,
dt,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
td,
th,
caption,
address,
textarea,
legend,
blockquote,
figure {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4 {
    font-size: 100%;
    font-weight: normal;
}

img,
fieldset {
    border: 0;
}

ul,
ol {
    list-style-type: none;
}

table {
    border-collapse: collapse;
}

th,
td {
    vertical-align: top;
}

ins {
    text-decoration: none;
}

a:link, a:visited {
    outline: none;
    color: inherit;
    text-decoration: none;
}

* {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}

body {
    font-family: GerberaRegular,"Segoe UI", "Droid Sans", Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

@media (prefers-color-scheme: light) {
    body {
        background: #FFF;
        color: #141414;
    }
}
@media (prefers-color-scheme: dark) {
    body {
        background: #141414;
        color: #FFF;
    }
}

#root, #popup {
    color-scheme: light dark;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    line-height: 24px;
}

@media screen and (min-width: 1920px) {
    #root, #popup {
        font-size: 20px;
        line-height: 32px;
    }
}

::selection {
    background: rgba(118, 51, 255, 0.16);
}

img,
svg {
    vertical-align: top;
}
